import {Component, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {Project, ProjectsService} from '../../api';

@Component({
  selector: 'app-project-terms-and-deposit',
  templateUrl: './project-terms-and-deposit.component.html',
  styleUrls: ['./project-terms-and-deposit.component.scss']
})
export class ProjectTermsAndDepositComponent implements OnInit {
  id!: string;
  project?: Project;
  termsAgreement: boolean;
  sub: any;
  checkoutStatus: boolean;

  constructor(private route: ActivatedRoute,
              private router: Router,
              private projectService: ProjectsService) {
    this.termsAgreement = false;
    this.checkoutStatus = false;
  }

  ngOnInit(): void {
    this.sub = this.route.params.subscribe(params => {
      this.id = params.id;
      this.projectService.apiProjectsIdGet(this.id).subscribe(x => this.project = x);
    });

    window.scrollTo(0, 0);
  }

  submitTermsAndPayment(): void {
    this.projectService.apiProjectsAgreeToTermsPut(this.id).subscribe(x => {
      this.router.navigateByUrl(`/project/${this.id}/confirmation`);
    });
  }

  checkout(): void {
    this.checkoutStatus = true;
  }

  onStripeSuccess(event: boolean): void {
    this.checkoutStatus = true;
  }
}
