<div class="row">
  <div class="col-md-2"></div>
  <div class="col-md-8">
    <h3 class="text-center mt-3 mb-3">My Projects</h3>
  </div>
  <div class="col-md-2 text-right mt-3">
    <button class="btn btn-block btn-success" [routerLink]="['/']">New Project</button>
  </div>
  <div class="col-12">
    <table class="table table-bordered">
      <thead>
      <tr>
        <th>Title</th>
        <th>Package</th>
        <th>Description</th>
        <th>Status</th>
        <th>Actions</th>
      </tr>
      </thead>
      <tbody>
      <tr *ngFor="let project of projects">
        <td>{{project?.title}}</td>
        <td>{{project?.packageModel?.description}}</td>
        <td>{{project?.description}}</td>
        <td>{{getStatus(project.status)}}</td>
        <td>
          <button class="btn btn-sm btn-primary" [routerLink]="['/dashboard/details', project.id]">Details</button>
        </td>
      </tr>
      <tr *ngIf="projects && projects.length === 0">
        <td colspan="5" class="text-center">
          <p>It looks like you haven't started a project yet.</p>
          <button class="btn btn-block btn-success" [routerLink]="['/']">Start a New Project</button>
        </td>
      </tr>
      </tbody>
    </table>
  </div>
</div>

