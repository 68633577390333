import { Component, OnInit } from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {FileSlim, Project, ProjectsService, ProjectStatus} from '../../../api';
import {environment} from '../../../environments/environment';

@Component({
  selector: 'app-details',
  templateUrl: './details.component.html',
  styleUrls: ['./details.component.scss']
})
export class DetailsComponent implements OnInit {

  id!: string;
  project?: Project;
  recordings?: Array<FileSlim>;
  showPayment?: boolean;
  showUpgrade?: boolean;
  updateRequest?: string;
  sub: any;
  checkoutStatus: boolean;
  purchaseAmount!: number;

  constructor(private route: ActivatedRoute,
              private projectService: ProjectsService) {
    this.checkoutStatus = false;
  }

  ngOnInit(): void {
    this.sub = this.route.params.subscribe(params => {
      this.id = params.id;
      this.projectService.apiProjectsIdGet(this.id).subscribe(x => this.project = x);
      this.projectService.apiProjectsIdFileSlimRecordingGet(this.id).subscribe(x => this.recordings = x);
    });

    this.showPayment = false;
    this.showUpgrade = false;
  }

  startPayment(): void {
    this.showPayment = true;
  }

  declineProject(): void {
    this.projectService.apiProjectsClientCancelPut(this.id).subscribe(a => {
      this.projectService.apiProjectsIdGet(this.id).subscribe(x => this.project = x);
    });
  }

  downloadFile(url: string|undefined): void {
    window.open(url, '_blank');
  }

  getFileUrl(id: string | undefined, url: string | undefined): string {
    if (url) {
      return url;
    }
    return `${environment.apiConfiguration.basePath}/api/Public/Files/${id}`;
  }

  getExpirationDate(date?: string): Date {
    const d = new Date();
    const year = d.getFullYear();
    const month = d.getMonth();
    const day = d.getDate();

    return new Date(year + 1, month, day);
  }

  getProjectCost(project: Project): number {
    if ((project.packageModel?.upgradeCost && project.projectCost)) {
      return (project.packageModel.upgradeCost - project.projectCost);
    }
    return 0;
  }

  getRemainingBalance(project: Project): number {
    if (project?.projectCost) {
      return project.projectCost - 50;
    }
    return 0;
  }

  onStripeSuccess(event: boolean): void {
    this.checkoutStatus = true;
    this.projectService.apiProjectsIdGet(this.id).subscribe(x => this.project = x);
  }

  onIntentResolve(event: number): void {
    this.purchaseAmount = event;
  }

  acceptCustomProject(): void {
    this.projectService.apiProjectsClientAcceptCustomProjectIdPut(this.id).subscribe(a => {
      this.projectService.apiProjectsIdGet(this.id).subscribe(x => this.project = x);
    });
  }

}
