import { Injectable } from '@angular/core';
import {of, pipe} from 'rxjs';
import {delay} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ErrorService {

  message: string;
  constructor() {
    this.message = '';
    // this.setMessage('Test');
  }

  setMessage(msg: string): void {
    this.message = msg;
    const obs = of(msg).pipe(delay(5000));
    obs.subscribe(x => {
      this.message = '';
    });
  }
}
