<div class="row">
  <div class="col-12">
    <h3 class="text-center mt-3 mb-3">Project Terms and Deposit</h3>
  </div>
  <div class="col-12">
    <div class="card mt-2 mb-2">
      <div class="card-header">Project Details</div>
      <div class="card-body" *ngIf="project">
        <p><strong>Title:</strong> {{project.title}}</p>
        <p><strong>Description:</strong> {{project.description}}</p>
        <p><strong>Style:</strong> {{project.style}}</p>
        <p><strong>Audience:</strong> {{project.targetAudience}}</p>
        <p><strong>Due Date:</strong> {{project.dueDate | date}}</p>
        <p><strong>File Format</strong> {{project.fileFormat}}</p>
        <p><strong>Package</strong> {{project.packageModel?.description}}</p>
        <p *ngIf="project.packageModel?.audience"><strong>Audience:</strong> {{project.packageModel?.audience?.name}}</p>
        <p *ngIf="project.packageModel?.licenseType == 1"><strong>License:</strong> One-Year</p>
        <p *ngIf="project.packageModel?.licenseType == 2"><strong>License:</strong> Perpetual</p>
        <p><strong>Cost:</strong> {{project.packageModel?.cost | currency:'USD'}}</p>
      </div>
    </div>
  </div>
  <div class="col-12">
    <div class="card mt-2 mb-2">
      <div class="card-header">Project Terms</div>
      <div class="card-body">
        <p>By submitting this project request, you agree to the following terms and conditions:</p>
        <div class="row">
          <div class="col-md-2"></div>
          <div class="col-md-8">
            <div class="card">
              <div class="card-body">
                <p>ApexxVO/Jason Fredregill is non-union.</p>
                <p>All rates are for use for within one year from recording date.</p>
                <p>If perpetual use is needed, please select the ‘In Perpetuity’ option</p>
                <p>Voiceover is delivered raw, without music or editing.</p>
                <p>ApexxVO/Jason Fredregill reserves the right to utilize any/all produced content featuring ApexxVO/Jason Fredregill voiceover work for use in demonstration, advertisement, self-promotion and competition purposes without further consent or approval.</p>
              </div>
            </div>
          </div>
          <div class="col-md-2"></div>
        </div>
        <div class="form-check mt-3 text-right">
          <input class="form-check-input" type="checkbox" id="defaultCheck1" [(ngModel)]="termsAgreement">
          <label class="form-check-label" for="defaultCheck1">
            I agree to the terms and conditions.
          </label>
        </div>
      </div>
    </div>
    <div class="card mt-2 mb-2">
      <div class="card-header">Deposit Payment</div>
      <div class="card-body">
        <p>To submit this project, a deposit payment of $50 must be paid by credit card.</p>
        <p>Upon authorization, your credit card will be pre-authorized. Your card will not be charged until the project is reviewed and accepted by ApexxVO. If for some reason the project cannot be accepted, the credit card authorization will be cancelled.</p>
        <p>Once the project is complete, and you've had a chance to review the project files, payment will be collected for the remainder of the project fee prior to granting access to the project files for download.</p>
        <div class="row">
          <div class="col-md-6 text-center mt-2">
            <h5>Deposit Payment: $50.00</h5>
            <p class="small">Payments accepted securely using Stripe.</p>
          </div>
          <div class="col-md-6 mt-3 mb-2">
            <app-stripe-hold [projectId]="id" (paymentSuccess)="onStripeSuccess($event)"></app-stripe-hold>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="col-md-8"></div>
  <div class="col-md-4 text-right">
    <button class="btn btn-primary btn-block" (click)="submitTermsAndPayment()" [disabled]="termsAgreement !== true || checkoutStatus !== true">Submit Project</button>
    <p class="small" *ngIf="!termsAgreement">Please accept the terms and conditions to continue.</p>
    <p class="small" *ngIf="termsAgreement && !checkoutStatus">Please submit your deposit payment to continue.</p>
  </div>
</div>
