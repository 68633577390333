import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {Project, ProjectsService} from '../../api';

@Component({
  selector: 'app-project-confirmation',
  templateUrl: './project-confirmation.component.html',
  styleUrls: ['./project-confirmation.component.scss']
})
export class ProjectConfirmationComponent implements OnInit {

  id!: string;
  project?: Project;
  sub: any;

  constructor(private route: ActivatedRoute,
              private router: Router,
              private projectService: ProjectsService) { }

  ngOnInit(): void {
    this.sub = this.route.params.subscribe(params => {
      this.id = params.id;
      this.projectService.apiProjectsIdGet(this.id).subscribe(x => this.project = x);
    });

    window.scrollTo(0, 0);
  }

}
