import { Component, OnInit } from '@angular/core';
import {Project, ProjectsService} from '../../api';

@Component({
  selector: 'app-user-dashboard',
  templateUrl: './user-dashboard.component.html',
  styleUrls: ['./user-dashboard.component.scss']
})
export class UserDashboardComponent implements OnInit {

  projects?: Array<Project>;

  constructor(private projectService: ProjectsService) { }

  ngOnInit(): void {
    this.getProjects();
  }

  getProjects(): void {
    this.projectService.apiProjectsMineGet().subscribe(x => {
      this.projects = x;
    });
  }

  getStatus(status: number|undefined): string {
    switch (status) {
      case 0:
        return 'Pending';
      case 1:
        return 'Approved';
      case 2:
        return 'Declined';
      case 3:
        return 'Recorded';
      case 4:
        return 'Paid';
      case 5:
        return 'Cancelled';
      case 7:
        return 'Quoted';
      default:
        return '';
    }
  }
}
