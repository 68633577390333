<div class="row">
  <div class="col-12">
    <h3 class="text-center mt-3 mb-3">Enter your Project Details</h3>
  </div>
  <div class="col-12">
    <div class="card mt-2 mb-2">
      <div class="card-header">Package Info</div>
      <div class="card-body" *ngIf="!package">
        <app-loading></app-loading>
      </div>
      <div class="card-body" *ngIf="package">
        <p><strong>Category:</strong> {{package.packageCategory?.name}}</p>
        <p *ngIf="package.packageSubcategory"><strong>Subcategory:</strong> {{package.packageSubcategory.name}}</p>
        <p *ngIf="package.audience"><strong>Audience:</strong> {{package.audience.name}}</p>
        <p><strong>Description:</strong> {{package.description}}</p>
        <p *ngIf="package.licenseType == 1"><strong>License:</strong> One-Year</p>
        <p *ngIf="package.licenseType == 2"><strong>License:</strong> Perpetual</p>
        <p><strong>Cost:</strong> {{package.isCustomQuote ? 'Custom' : package.cost | currency:'USD'}}</p>
      </div>
    </div>
  </div>
  <div class="col-12" *ngIf="package">
    <div class="card mt-2 mb-2">
      <div class="card-header">Voiceover Project Details</div>
      <div class="card-body">
        <div class="mb-3">
          <label for="projectName" class="form-label">What would you like to call this project?</label>
          <input id="projectName" type="text" class="form-control" [(ngModel)]="project.title"/>
        </div>
        <div class="mb-3">
          <label for="projectDescription" class="form-label">Tell us about your project...</label>
          <textarea class="form-control" id="projectDescription" rows="3" [(ngModel)]="project.description"></textarea>
        </div>
        <div class="mb-3">
          <label for="projectDueDate" class="form-label">When do you need the voiceover to be completed by?</label>
          <input id="projectDueDate" type="date" class="form-control" [(ngModel)]="project.dueDate"/>
        </div>
        <div class="mb-3">
          <label for="projectStyle" class="form-label">What style of voiceover are you looking for?</label>
          <textarea class="form-control" id="projectStyle" rows="3" [(ngModel)]="project.style"></textarea>
        </div>
        <div class="mb-3">
          <label for="projectAudience" class="form-label">Who is the audience of your project?</label>
          <textarea class="form-control" id="projectAudience" rows="3" [(ngModel)]="project.targetAudience"></textarea>
        </div>
        <div class="form-group mb-3">
          <label for="projectFileFormat" class="form-label">What file type would you like to receive?</label>
          <select class="form-control" id="projectFileFormat" [(ngModel)]="project.fileFormat">
            <option value=""></option>
            <option value="AAC">AAC</option>
            <option value="AIFF">AIFF</option>
            <option value="MP3">MP3</option>
            <option value="WAV">WAV</option>
          </select>
        </div>
      </div>
    </div>
  </div>
  <div class="col-md-9"></div>
  <div class="col-md-3 text-right">
    <button class="btn btn-success" (click)="submitProjectDetails()">Save and Next Page</button>
  </div>
  <!--  <pre>{{package|json}}</pre>-->
</div>
