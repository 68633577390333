<form id="payment-form">
  <div class="row">
    <div class="col-md-8">
      <div id="card-element" class="form-control">
        <!-- Elements will create input elements here -->
      </div>
      <div id="card-errors" role="alert" class="small">
        <!--        {{errorMessage}}-->
        <!-- We'll put the error messages in this element -->
      </div>
    </div>
    <div class="col-md-4">
      <button class="btn btn-block btn-primary" id="submit" [disabled]="(errorMessage.length >= 1 && status === 0) || status > 0">Authorize Card</button>
    </div>
  </div>
  <div class="row mt-3" *ngIf="status === 0 && errorMessage.length >= 1">
    <div class="col-12">
      <ngb-alert [dismissible]="false" type="warning">
        {{errorMessage}}
      </ngb-alert>
    </div>
  </div>
  <div class="row mt-3" *ngIf="status === 1">
    <div class="col-12">
      <ngb-alert [dismissible]="false" type="info">
        Authorization is processing. Please wait.
      </ngb-alert>
    </div>
  </div>
  <div class="row mt-3" *ngIf="status === -1">
    <div class="col-12">
      <ngb-alert [dismissible]="false" type="danger" *ngIf="errorMessage.length === 0">
        An error has occurred. Please try again.
      </ngb-alert>
      <ngb-alert [dismissible]="false" type="danger" *ngIf="errorMessage.length > 0">
        {{errorMessage}}
      </ngb-alert>
    </div>
  </div>
  <div class="row mt-3" *ngIf="status === 2">
    <div class="col-12">
      <ngb-alert [dismissible]="false" type="success">
        Remaining payment was successfully authorized.
      </ngb-alert>
    </div>
  </div>
</form>
