<div class="row">
  <div class="col-md-12">
    <h2 class="text-center mt-3">Admin Dashboard</h2>
  </div>
  <div class="col-12" *ngIf="!pendingProjects">
    <app-loading></app-loading>
  </div>
  <div class="col-12" *ngIf="pendingProjects">
    <ul ngbNav #nav="ngbNav" [(activeId)]="active" class="nav-tabs">
      <li [ngbNavItem]="1">
        <a ngbNavLink>New Requests ({{pendingProjects?.length}})</a>
        <ng-template ngbNavContent>
          <h4 class="text-center">New Requests</h4>
          <table class="table table-bordered">
            <thead>
            <tr>
              <th>Title</th>
              <th>Package</th>
              <th>Description</th>
              <th>Status</th>
              <th>Actions</th>
            </tr>
            </thead>
            <tbody>
            <tr *ngFor="let project of pendingProjects">
              <td>{{project.title}}</td>
              <td>{{project.packageModel?.description}}</td>
              <td>{{project.description}}</td>
              <td>{{getStatus(project.status)}}</td>
              <td>
                <button class="btn btn-sm btn-primary" [routerLink]="['/admin/dashboard/details', project.id]">Details</button>
              </td>
            </tr>
            </tbody>
          </table>
        </ng-template>
      </li>
      <li [ngbNavItem]="2">
        <a ngbNavLink>Ready for Recording ({{acceptedProjects?.length}})</a>
        <ng-template ngbNavContent>
          <h4 class="text-center">Ready for Recording</h4>
          <table class="table table-bordered">
            <thead>
            <tr>
              <th>Title</th>
              <th>Package</th>
              <th>Description</th>
              <th>Status</th>
              <th>Actions</th>
            </tr>
            </thead>
            <tbody>
            <tr *ngFor="let project of acceptedProjects">
              <td>{{project.title}}</td>
              <td>{{project.packageModel?.description}}</td>
              <td>{{project.description}}</td>
              <td>{{getStatus(project.status)}}</td>
              <td>
                <button class="btn btn-sm btn-primary" [routerLink]="['/admin/dashboard/details', project.id]">Details</button>
              </td>
            </tr>
            </tbody>
          </table>
        </ng-template>
      </li>
      <li [ngbNavItem]="3">
        <a ngbNavLink>Pending Payment ({{recordedProjects?.length}})</a>
        <ng-template ngbNavContent>
          <h4 class="text-center">Pending Payment</h4>
          <table class="table table-bordered">
            <thead>
            <tr>
              <th>Title</th>
              <th>Package</th>
              <th>Description</th>
              <th>Status</th>
              <th>Actions</th>
            </tr>
            </thead>
            <tbody>
            <tr *ngFor="let project of recordedProjects">
              <td>{{project.title}}</td>
              <td>{{project.packageModel?.description}}</td>
              <td>{{project.description}}</td>
              <td>{{getStatus(project.status)}}</td>
              <td>
                <button class="btn btn-sm btn-primary" [routerLink]="['/admin/dashboard/details', project.id]">Details</button>
              </td>
            </tr>
            </tbody>
          </table>
        </ng-template>
      </li>
      <li [ngbNavItem]="4">
        <a ngbNavLink>Complete ({{completedProjects?.length}})</a>
        <ng-template ngbNavContent>
          <h4 class="text-center">Paid and Complete</h4>
          <table class="table table-bordered">
            <thead>
            <tr>
              <th>Title</th>
              <th>Package</th>
              <th>Description</th>
              <th>Status</th>
              <th>Actions</th>
            </tr>
            </thead>
            <tbody>
            <tr *ngFor="let project of completedProjects">
              <td>{{project.title}}</td>
              <td>{{project.packageModel?.description}}</td>
              <td>{{project.description}}</td>
              <td>{{getStatus(project.status)}}</td>
              <td>
                <button class="btn btn-sm btn-primary" [routerLink]="['/admin/dashboard/details', project.id]">Details</button>
              </td>
            </tr>
            </tbody>
          </table>
        </ng-template>
      </li>
      <li [ngbNavItem]="5">
        <a ngbNavLink>Declined ({{declinedProjects?.length}})</a>
        <ng-template ngbNavContent>
          <h4 class="text-center">Declined by ApexxVO</h4>
          <table class="table table-bordered">
            <thead>
            <tr>
              <th>Title</th>
              <th>Package</th>
              <th>Description</th>
              <th>Status</th>
              <th>Actions</th>
            </tr>
            </thead>
            <tbody>
            <tr *ngFor="let project of declinedProjects">
              <td>{{project.title}}</td>
              <td>{{project.packageModel?.description}}</td>
              <td>{{project.description}}</td>
              <td>{{getStatus(project.status)}}</td>
              <td>
                <button class="btn btn-sm btn-primary" [routerLink]="['/admin/dashboard/details', project.id]">Details</button>
              </td>
            </tr>
            </tbody>
          </table>
        </ng-template>
      </li>
      <li [ngbNavItem]="6">
        <a ngbNavLink>Cancelled ({{cancelledProjects?.length}})</a>
        <ng-template ngbNavContent>
          <h4 class="text-center">Cancelled by Client</h4>
          <table class="table table-bordered">
            <thead>
            <tr>
              <th>Title</th>
              <th>Package</th>
              <th>Description</th>
              <th>Status</th>
              <th>Actions</th>
            </tr>
            </thead>
            <tbody>
            <tr *ngFor="let project of cancelledProjects">
              <td>{{project.title}}</td>
              <td>{{project.packageModel?.description}}</td>
              <td>{{project.description}}</td>
              <td>{{getStatus(project.status)}}</td>
              <td>
                <button class="btn btn-sm btn-primary" [routerLink]="['/admin/dashboard/details', project.id]">Details</button>
              </td>
            </tr>
            </tbody>
          </table>
        </ng-template>
      </li>
    </ul>
    <div [ngbNavOutlet]="nav" class="mt-2"></div>
  </div>
</div>

