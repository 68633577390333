import { Component, OnInit } from '@angular/core';
import {RerouteService} from '../reroute.service';
import {Router} from '@angular/router';
import {AuthService} from '@auth0/auth0-angular';

@Component({
  selector: 'app-rerouter',
  templateUrl: './rerouter.component.html',
  styleUrls: ['./rerouter.component.scss']
})
export class RerouterComponent implements OnInit {

  constructor(private authService: AuthService,
              private rerouteService: RerouteService,
              private router: Router) { }

  ngOnInit(): void {
    const route = this.rerouteService.getRoute();

    this.authService.isAuthenticated$.subscribe(x => {
      if (route) {
        this.router.navigateByUrl(route);
      } else {
        this.router.navigateByUrl('/');
      }
    });
  }
}
