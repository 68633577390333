import { Component, OnInit } from '@angular/core';
import {UserProfile, UserProfilesService} from '../../api';
import {ActivatedRoute, Router} from '@angular/router';
import {ErrorService} from '../error.service';

@Component({
  selector: 'app-user-profile',
  templateUrl: './user-profile.component.html',
  styleUrls: ['./user-profile.component.scss']
})
export class UserProfileComponent implements OnInit {

  constructor(private userProfileService: UserProfilesService, private router: Router, private errorService: ErrorService, private route: ActivatedRoute) { }

  userProfile?: UserProfile;
  packageId?: number;

  ngOnInit(): void {
    this.userProfileService.apiUserProfilesMineGet().subscribe(x => this.userProfile = x);

    this.route.queryParams.subscribe(x => {
      this.packageId = x.packageId ?? null;
    });
  }

  saveProfile(): void {
    if (!this.userProfile || !this.userProfile.id) {
      return;
    }

    const verified = this.verifyProfile();

    if (!verified) {
      this.errorService.setMessage('Please complete all fields before submitting.');
      return;
    }

    this.userProfileService.apiUserProfilesIdPut(this.userProfile.id, this.userProfile).subscribe(x => {
      if (this.packageId != null) {
        this.router.navigateByUrl('//project/step-1/' + this.packageId);
      }
      else {
        this.router.navigateByUrl('/dashboard');
      }
    });
  }

  verifyProfile(): boolean {
    return this.userProfile != null
      && this.userProfile.firstName != null
      && this.userProfile.lastName != null
      && this.userProfile.company != null
      && this.userProfile.phone != null
      && this.userProfile.address != null
      && this.userProfile.city != null
      && this.userProfile.state != null;
  }

  getButtonText(): string {
    return this.packageId == null ? 'Save Profile' : 'Start New Project';
  }
}
