import { Component, OnInit } from '@angular/core';
import {FileSlim, FilesService, Project, ProjectsService} from '../../api';
import {ActivatedRoute} from '@angular/router';
import {environment} from '../../environments/environment';
import {FileSystemDirectoryEntry, FileSystemFileEntry, NgxFileDropEntry} from 'ngx-file-drop';

@Component({
  selector: 'app-admin-details',
  templateUrl: './admin-details.component.html',
  styleUrls: ['./admin-details.component.scss']
})
export class AdminDetailsComponent implements OnInit {

  id!: string;
  project?: Project;
  scripts?: Array<FileSlim>;
  files: NgxFileDropEntry[] = [];
  recordings?: Array<FileSlim>;
  showDecline?: boolean;
  declineReason?: string;
  customCost?: number;
  sub: any;

  constructor(private route: ActivatedRoute,
              private projectService: ProjectsService,
              private fileService: FilesService) { }

  ngOnInit(): void {
    this.sub = this.route.params.subscribe(params => {
      this.id = params.id;
      this.projectService.apiProjectsIdGet(this.id).subscribe(x => this.project = x);
      this.projectService.apiProjectsIdFileSlimScriptGet(this.id).subscribe(x => this.scripts = x);
      this.projectService.apiProjectsIdFileSlimRecordingGet(this.id).subscribe(x => this.recordings = x);
    });

    this.showDecline = false;
  }

  acceptProject(): void {
    this.projectService.apiProjectsAdminAcceptPut(this.id).subscribe(a => {
      this.projectService.apiProjectsIdGet(this.id).subscribe(x => this.project = x);
    });
  }

  acceptCustomProject(): void {
    this.projectService.apiProjectsAcceptCustomPut(this.id, this.customCost).subscribe(a => {
      this.projectService.apiProjectsIdGet(this.id).subscribe(x => this.project = x);
    });
  }

  declineProject(): void {
    this.showDecline = true;
  }

  confirmDecline(): void {
    this.projectService.apiProjectsDeclinePut(this.id, this.declineReason).subscribe(a => {
      this.projectService.apiProjectsIdGet(this.id).subscribe(x => this.project = x);
    });
  }

  sendToClient(): void {
    this.projectService.apiProjectsAdminSubmitRecordingPut(this.id).subscribe(a => {
      this.projectService.apiProjectsIdGet(this.id).subscribe(x => this.project = x);
    });
  }

  getStatus(status: number): string {
    switch (status) {
      case 0:
        return 'Pending';
      case 1:
        return 'Approved';
      case 2:
        return 'Declined';
      case 3:
        return 'Recorded';
      case 4:
        return 'Paid';
      case 5:
        return 'Cancelled';
      default:
        return '';
    }
  }

  downloadFile(url: string|undefined): void {
    window.open(url, '_blank');
  }

  public getFileUrl(id: string | undefined, url: string | undefined): string {
    if (url) {
      return url;
    }
    return `${environment.apiConfiguration.basePath}/api/Public/Files/${id}`;
  }

  public dropped(files: NgxFileDropEntry[]): void {
    this.files = files;
    for (const droppedFile of files) {

      // Is it a file?
      if (droppedFile.fileEntry.isFile) {
        const fileEntry = droppedFile.fileEntry as FileSystemFileEntry;
        fileEntry.file((file: File) => {

          this.fileService.apiFilesRecordingProjectIdPost(this.id, file).subscribe(x => {
            this.projectService.apiProjectsIdFileSlimRecordingGet(this.id).subscribe(y => this.recordings = y);
          });

          // Here you can access the real file
          console.log(droppedFile.relativePath, file);
        });
      } else {
        // It was a directory (empty directories are added, otherwise only files)
        const fileEntry = droppedFile.fileEntry as FileSystemDirectoryEntry;
        console.log(droppedFile.relativePath, fileEntry);
      }
    }
  }

  public fileOver(event: Event): void{
    console.log(event);
  }

  public fileLeave(event: Event): void{
    console.log(event);
  }
}
