<div class="row">
  <div class="col-12">
    <a [routerLink]="['/admin/dashboard']">&lt; Back to Dashboard</a>
  </div>
  <div class="col-12">
    <h3 class="text-center mt-3 mb-3">Project Details</h3>
  </div>
  <div class="col-md-6">
    <div class="card mt-2 mb-2">
      <div class="card-header">Project Info</div>
      <div class="card-body" *ngIf="project">
        <p><strong>Title:</strong> {{project.title}}</p>
        <p><strong>Description:</strong> {{project.description}}</p>
        <p><strong>Style:</strong> {{project.style}}</p>
        <p><strong>Audience:</strong> {{project.targetAudience}}</p>
        <p><strong>Due Date:</strong> {{project.dueDate | date}}</p>
        <p><strong>File Format:</strong> {{project.fileFormat}}</p>
        <p><strong>Date Submitted:</strong> {{project.termsAgreedDate | date}}</p>
        <p *ngFor="let script of scripts"><strong>Script File:</strong> {{script.name}}
          <button class="btn btn-sm btn-primary" (click)="downloadFile(getFileUrl(script.id!, script.url!))">Download Script</button></p>
      </div>
    </div>
  </div>
  <div class="col-md-6">
    <div class="card mt-2 mb-2">
      <div class="card-header">Package</div>
      <div class="card-body" *ngIf="project">
        <p><strong>Category:</strong> {{project.packageModel?.packageCategory?.name}}</p>
        <p *ngIf="project.packageModel?.packageSubcategory"><strong>Subcategory:</strong> {{project.packageModel?.packageSubcategory?.name}}</p>
        <p *ngIf="project.packageModel?.audience"><strong>Audience:</strong> {{project.packageModel?.audience?.name}}</p>
        <p><strong>Description:</strong> {{project.packageModel?.description}}</p>
        <p *ngIf="project.packageModel?.licenseType == 1"><strong>License:</strong> One-Year</p>
        <p *ngIf="project.packageModel?.licenseType == 2"><strong>License:</strong> Perpetual</p>
        <p><strong>Cost:</strong> {{project.projectCost | currency:'USD'}}</p>
      </div>
    </div>
    <div class="card mt-2 mb-2">
      <div class="card-header">Project Requester</div>
      <div class="card-body" *ngIf="project && project.userProfile">
        <p><strong>Name:</strong> {{project.userProfile.firstName}} {{project.userProfile.lastName}}</p>
        <p><strong>Company:</strong> {{project.userProfile.company}}</p>
        <p><strong>Email:</strong> {{project.userProfile.email}}</p>
        <p><strong>Phone:</strong> {{project.userProfile.phone}}</p>
      </div>
    </div>
  </div>
  <div class="col-12" *ngIf="project?.status === 0">
    <div class="card mt-2 mb-2">
      <div class="card-header">Project Pending</div>
      <div class="card-body" *ngIf="!project?.packageModel?.isCustomQuote">
        <p class="text-center">This project is awaiting your review. Please review and accept or decline.</p>
        <div class="row text-center">
          <div class="col-6">
            <button class="btn btn-success" (click)="acceptProject()">Accept Project</button>
          </div>
          <div class="col-6">
            <button class="btn btn-danger" (click)="declineProject()">Decline Project</button>
          </div>
          <div class="col-12" *ngIf="showDecline">
            <label for="declineReason" class="form-label">Why are you declining this project?</label>
            <input id="declineReason" type="text" class="form-control" [(ngModel)]="declineReason"/>
            <button class="btn btn-block btn-warning mt-2" (click)="confirmDecline()">Confirm Declining Project</button>
          </div>
        </div>
      </div>
      <div class="card-body" *ngIf="project?.packageModel?.isCustomQuote">
        <p>This project requires a custom quote. Please enter the project cost and accept, or decline.</p>
        <div class="row">
          <div class="col-12">
            <div class="form-group">
              <label for="customCost">Custom Project Cost:</label>
              <input id="customCost" type="number" step="0.01" class="form-control mb-3" [(ngModel)]="customCost"/>
            </div>
          </div>
          <div class="col-6">
            <button class="btn btn-success" (click)="acceptCustomProject()">Accept Project</button>
          </div>
          <div class="col-6">
            <button class="btn btn-danger" (click)="declineProject()">Decline Project</button>
          </div>
          <div class="col-12" *ngIf="showDecline">
            <label for="declineReasonC" class="form-label">Why are you declining this project?</label>
            <input id="declineReasonC" type="text" class="form-control" [(ngModel)]="declineReason"/>
            <button class="btn btn-block btn-warning mt-2" (click)="confirmDecline()">Confirm Declining Project</button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="col-12" *ngIf="project?.status === 1">
    <div class="card mt-2 mb-2">
      <div class="card-header">Project Accepted</div>
      <div class="card-body">
        <p>This project has been accepted, and is ready to record!</p>
        <div class="row">
<!--          <div class="col-md-3 mb-3 text-center">-->
<!--            <label for="recordingFile" class="form-label">Choose recording file</label>-->
<!--          </div>-->
<!--          <div class="col-md-6 mb-3">-->
<!--            <input id="recordingFile" type="file" class="form-control"/>-->
<!--          </div>-->
<!--          <div class="col-md-3 text-center">-->
<!--            <button class="btn btn-sm btn-success">Upload File</button>-->
<!--          </div>-->
          <div class="col-12">
            <div class="center">
              <ngx-file-drop dropZoneLabel="Drop files here" (onFileDrop)="dropped($event)"
                             (onFileOver)="fileOver($event)" (onFileLeave)="fileLeave($event)">
                <ng-template ngx-file-drop-content-tmp let-openFileSelector="openFileSelector">
                  Drop recording files here. &nbsp;
                  <button type="button" (click)="openFileSelector()" class="btn btn-primary">Browse Files</button>
                </ng-template>
              </ngx-file-drop>
<!--              <div class="upload-table">-->
<!--                <table class="table">-->
<!--                  <thead>-->
<!--                  <tr>-->
<!--                    <th>Name</th>-->
<!--                  </tr>-->
<!--                  </thead>-->
<!--                  <tbody class="upload-name-style">-->
<!--                  <tr *ngFor="let item of files; let i=index">-->
<!--                    <td><strong>{{ item.relativePath }}</strong></td>-->
<!--                  </tr>-->
<!--                  </tbody>-->
<!--                </table>-->
<!--              </div>-->
            </div>
            <table class="table table-bordered mt-4">
              <tr *ngFor="let r of recordings">
                <td width="50%" class="text-center">{{r.name}}</td>
                <td width="50%" class="text-center">
                  <!-- Here we are requesting to use the native controls. -->
<!--                  <vm-player controls style="&#45;&#45;vm-player-theme: #6653a3">-->
<!--                    <vm-audio cross-origin>-->
<!--                      <source data-src="{{getFileUrl(r.id!)}}" type="audio/mp3" />-->
<!--                    </vm-audio>-->
<!--                  </vm-player>-->

                  <vm-player theme="light" style="--vm-player-theme: #6653a3">
                    <vm-audio cross-origin>
                      <source data-src="{{getFileUrl(r.id!, r.url!)}}" type="audio/mp3" />
                    </vm-audio>
                    <vm-ui>
                      <vm-controls full-width>
                        <vm-playback-control></vm-playback-control>
                        <vm-current-time></vm-current-time>
                        <vm-scrubber-control></vm-scrubber-control>
                        <vm-volume-control></vm-volume-control>
                        <vm-settings-control></vm-settings-control>
                      </vm-controls>
                    </vm-ui>
                  </vm-player>

                  <button class="btn btn-sm btn-success" (click)="downloadFile(getFileUrl(r.id!, r.url!))">Download</button>
                </td>
              </tr>
            </table>
          </div>
          <div class="col-12">
            <button class="btn btn-block btn-warning" (click)="sendToClient()" [disabled]="!recordings || recordings?.length === 0">Send to Client for Preview</button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="col-12" *ngIf="project?.status === 2">
    <div class="card mt-2 mb-2">
      <div class="card-header">Project Declined</div>
      <div class="card-body">
        <p *ngIf="project?.declineReason">This project has been declined for the following reason: {{project?.declineReason}}</p>
        <p *ngIf="!project?.declineReason">This project has been declined for the following reason: [No reason provided]</p>
        <p>The project was cancelled.</p>
      </div>
    </div>
  </div>
  <div class="col-12" *ngIf="project?.status === 3">
    <div class="card mt-2 mb-2">
      <div class="card-header">Recorded</div>
      <div class="card-body">
        <p>This project has already been recorded, and is waiting for the client to review.</p>
        <table class="table table-bordered mt-4">
          <tr *ngFor="let r of recordings">
            <td width="75%" class="text-center">{{r.name}}</td>
            <td width="25%" class="text-center">
              <button class="btn btn-sm btn-success" (click)="downloadFile(getFileUrl(r.id!, r.url!))">Download</button>
            </td>
          </tr>
        </table>

      </div>
    </div>
  </div>
  <div class="col-12" *ngIf="project?.status === 4">
    <div class="card mt-2 mb-2">
      <div class="card-header">Paid and Finalized</div>
      <div class="card-body">
        <p>This project has been paid and finalized.</p>
      </div>
    </div>
  </div>
  <div class="col-12" *ngIf="project?.status === 5">
    <div class="card mt-2 mb-2">
      <div class="card-header">Project Declined</div>
      <div class="card-body">
        <p>This project was cancelled by the client.</p>
      </div>
    </div>
  </div>
  <div class="col-12" *ngIf="project?.status === 7">
    <div class="card mt-2 mb-2">
      <div class="card-header">Custom Pending Client Approval</div>
      <div class="card-body">
        <p>This project has been quoted for {{project?.projectCost | currency:'USD'}} and is pending approval by the client.</p>
      </div>
    </div>
  </div>
</div>
