import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class RerouteService {

  constructor() { }

  setRoute(route?: string): void {
    if (route) {
      localStorage.setItem('savedRoute', route);
    } else {
      localStorage.setItem('savedRoute', window.location.pathname);
    }
  }

  getRoute(): string|null {
    return localStorage.getItem('savedRoute');
  }

  getRedirectUri(): string {
    const uri = `${window.location.protocol}//${window.location.host}/reroute`;
    return uri;
  }
}
