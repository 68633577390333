import { Component, OnInit } from '@angular/core';
import {Package, PackagesService, Project, ProjectsService} from '../../api';
import {ActivatedRoute, Router} from '@angular/router';

@Component({
  selector: 'app-initial-project-purchase',
  templateUrl: './initial-project-purchase.component.html',
  styleUrls: ['./initial-project-purchase.component.scss']
})
export class InitialProjectPurchaseComponent implements OnInit {

  packageId?: number;
  package?: Package;
  project: Project;
  projectId?: string;
  sub: any;

  constructor(private route: ActivatedRoute,
              private router: Router,
              private packageService: PackagesService,
              private projectService: ProjectsService
  ) {
    this.project = ({} as Project);
  }

  ngOnInit(): void {
    this.sub = this.route.params.subscribe(params => {
      const parsed = parseInt(params.packageId, 10);
      if (isNaN(parsed)) { this.router.navigateByUrl('/'); }
      this.packageId = parsed;
      this.packageService.apiPackagesIdGet(this.packageId).subscribe(x => this.package = x);
    });

    window.scrollTo(0, 0);
  }

  submitProjectDetails(): void {
    this.project.packageId = this.packageId;
    this.project.projectCost = this.package?.cost;
    this.projectService.apiProjectsPost(this.project).subscribe(x => {
      this.projectId = x.id;
      this.router.navigateByUrl(`/project/${this.projectId}/upload-script`);
    });
  }

}
