import { Component, OnInit } from '@angular/core';
import {Project, ProjectsService} from '../../api';

@Component({
  selector: 'app-admin-dashboard',
  templateUrl: './admin-dashboard.component.html',
  styleUrls: ['./admin-dashboard.component.scss']
})
export class AdminDashboardComponent implements OnInit {

  projects?: Array<Project>;
  pendingProjects?: Array<Project>;
  acceptedProjects?: Array<Project>;
  declinedProjects?: Array<Project>;
  recordedProjects?: Array<Project>;
  completedProjects?: Array<Project>;
  paidProjects?: Array<Project>;
  cancelledProjects?: Array<Project>;
  filterStatus: number;
  active: number;


  constructor(private projectService: ProjectsService) {
    this.filterStatus = 0;
    this.active = 1;
  }

  ngOnInit(): void {
    this.getProjects();
  }

  getProjects(): void {
      this.projectService.apiProjectsStatusStatusGet(0).subscribe((x: Array<Project>) => {
        this.pendingProjects = x;
        this.projectService.apiProjectsStatusStatusGet(1).subscribe((y: Array<Project>) => this.acceptedProjects = y);
        this.projectService.apiProjectsStatusStatusGet(2).subscribe((y: Array<Project>) => this.declinedProjects = y);
        this.projectService.apiProjectsStatusStatusGet(3).subscribe((y: Array<Project>) => this.recordedProjects = y);
        this.projectService.apiProjectsStatusStatusGet(4)
          .subscribe((y: Array<Project>) => this.completedProjects = y);
        this.projectService.apiProjectsStatusStatusGet(5)
          .subscribe((y: Array<Project>) => this.cancelledProjects = y);
      });
  }


  getStatus(status: number|undefined): string {
    switch (status) {
      case 0:
        return 'Pending';
      case 1:
        return 'Approved';
      case 2:
        return 'Declined';
      case 3:
        return 'Recorded';
      case 4:
        return 'Paid';
      case 5:
        return 'Cancelled';
      default:
        return '';
    }
  }

}
