import {Component, OnInit} from '@angular/core';
import {AuthService} from '@auth0/auth0-angular';
import {PublicService, UserProfilesService} from '../api';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit{

  isAuthenticated: boolean;
  isAdmin: boolean;

  constructor(public authService: AuthService,
              public userProfilesService: UserProfilesService,
              private publicService: PublicService,
              private modalService: NgbModal) {
    this.isAuthenticated = false;
    this.isAdmin = false;
  }

  ngOnInit(): void {
    // this.publicService.apiPublicErrorGet().subscribe(x => {});

    this.authService.isAuthenticated$.subscribe(x => {
      this.isAuthenticated = x;
      if (this.isAuthenticated) {
        this.userProfilesService.apiUserProfilesCheckAdminGet().subscribe(y => this.isAdmin = y);
      }
    });

  }

  openHelpModal(content: any): void {
    this.modalService.open(content);
  }
}
