<nav class="navbar navbar-expand-lg navbar-light bg-light">
  <a class="navbar-brand" href="https://www.apexxvo.com">
    <img src="assets/apexxvologo.png" height="75px" width="75px"/>
  </a>
  <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarText" aria-controls="navbarText" aria-expanded="false" aria-label="Toggle navigation">
    <span class="navbar-toggler-icon"></span>
  </button>
  <div class="collapse navbar-collapse" id="navbarText">
    <ul class="navbar-nav mr-auto">
      <li class="nav-item">
        <a class="nav-link" [routerLink]="['/']">Choose a Package</a>
      </li>
      <li class="nav-item" *ngIf="isAuthenticated">
        <a class="nav-link" [routerLink]="['/dashboard']">View Projects</a>
      </li>
      <li class="nav-item" *ngIf="isAdmin">
        <a class="nav-link" [routerLink]="['/admin/dashboard']">Admin Dashboard</a>
      </li>
    </ul>
    <span class="navbar-text">
      <button class="btn btn-primary" *ngIf="!(isAuthenticated)" (click)="authService.loginWithRedirect()">Log In / Register</button>
      <div *ngIf="authService.user$ | async as user" ngbDropdown class="d-inline-block">
        <button class="btn btn-primary" id="dropdownProfile" ngbDropdownToggle>{{user.name}}</button>
        <div ngbDropdownMenu aria-labelledby="dropdownProfile">
          <button ngbDropdownItem [routerLink]="['/profile']">User Profile</button>
          <button ngbDropdownItem (click)="authService.logout()">Log Out</button>
          <button ngbDropdownItem (click)="openHelpModal(help)">Help</button>
        </div>
      </div>
    </span>
  </div>
</nav>
<div class="container">
  <app-master-alert></app-master-alert>
  <router-outlet></router-outlet>
</div>
<footer class="footer mt-4">
  <nav class="navbar navbar-light bg-light">
    <div class="container">
      <div class="row">
        <div class="col-12 pt-1 pb-1">
          <a class="navbar-brand" href="#">
            <img src="assets/apexxvologo.png" width="40" height="40" alt="">
          </a>
          <span>Copyright 2021, <a href="https://www.apexxvo.com" target="_blank">ApexxVO</a> | Created by <a href="https://www.illuminatedagency.com" target="_blank">Illuminated</a></span>
        </div>
      </div>
    </div>
  </nav>
</footer>

<ng-template #help let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Need Assistance?</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <p>Need help submitting or reviewing a project?</p>
    <p>Contact us via email: <a href="mailto:jason@apexxvo.com">jason@apexxvo.com</a></p>
  </div>
</ng-template>
