import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-pricing-grid',
  templateUrl: './pricing-grid.component.html',
  styleUrls: ['./pricing-grid.component.scss']
})
export class PricingGridComponent implements OnInit {

  constructor() { }

  active = 1;
  radioActive = 1;
  tvActive = 1;

  ngOnInit(): void {
  }

}
