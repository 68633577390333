<div class="row">
  <div class="col-12">
    <h3 class="text-center mt-3 mb-3">User Profile</h3>
    <p class="text-center">Please verify and update your user profile.</p>
  </div>
  <div class="col-12">
    <div class="card mt-2 mb-2" *ngIf="userProfile">
      <div class="card-header">User Information</div>
      <div class="card-body">
        <div class="mb-3">
          <label for="userFirstName" class="form-label">First Name</label>
          <input id="userFirstName" type="text" class="form-control" [(ngModel)]="userProfile.firstName"/>
        </div>
        <div class="mb-3">
          <label for="userLastName" class="form-label">Last Name</label>
          <input id="userLastName" type="text" class="form-control" [(ngModel)]="userProfile.lastName"/>
        </div>
        <div class="mb-3">
          <label for="userCompany" class="form-label">Company</label>
          <input id="userCompany" type="text" class="form-control" [(ngModel)]="userProfile.company"/>
        </div>
        <div class="mb-3">
          <label for="userEmail" class="form-label">Email</label>
          <input id="userEmail" type="email" class="form-control" [(ngModel)]="userProfile.email" disabled/>
        </div>
        <div class="mb-3">
          <label for="userPhone" class="form-label">Phone</label>
          <input id="userPhone" type="tel" class="form-control" [(ngModel)]="userProfile.phone"/>
        </div>
        <div class="mb-3">
          <div class="row">
            <div class="col-12 mb-3">
              <label for="userAddress" class="form-label">Address</label>
              <input id="userAddress" type="text" class="form-control" [(ngModel)]="userProfile.address"/>
            </div>
            <div class="col-md-6">
              <label for="userCity" class="form-label">City</label>
              <input id="userCity" type="text" class="form-control" [(ngModel)]="userProfile.city"/>
            </div>
            <div class="col-md-4">
              <label for="userState" class="form-label">State</label>
              <input id="userState" type="text" class="form-control" [(ngModel)]="userProfile.state"/>
            </div>
            <div class="col-md-2">
              <label for="userZip" class="form-label">Zip</label>
              <input id="userZip" type="text" class="form-control" [(ngModel)]="userProfile.zip"/>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="col-lg-8"></div>
  <div class="col-lg-4 text-right" *ngIf="userProfile">
    <button class="btn btn-block btn-primary" (click)="saveProfile()">{{getButtonText()}}</button>
  </div>
</div>
