import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor, HttpErrorResponse
} from '@angular/common/http';
import {Observable, of} from 'rxjs';
import {ErrorService} from './error.service';
import {map, tap} from 'rxjs/operators';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {

  constructor(private errorService: ErrorService) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return next.handle(request).pipe(
      tap(event => {},
          error => {
        if (error instanceof HttpErrorResponse) {
          this.errorService.setMessage('An error has occurred. Please try again.');
          // this.toasterService.error(err.error.message, err.error.title, { positionClass: 'toast-bottom-center' });
        }
      }));
  }
}
