<div class="row">
  <div class="col-12">
    <a [routerLink]="['/dashboard']">&lt; Back to Dashboard</a>
  </div>
  <div class="col-12">
    <h3 class="text-center mt-3 mb-3">Details</h3>
  </div>
  <div class="col-md-6">
    <div class="card mt-2 mb-2">
      <div class="card-header">Project Info</div>
      <div class="card-body" *ngIf="project">
        <p><strong>Title:</strong> {{project.title}}</p>
        <p><strong>Description:</strong> {{project.description}}</p>
        <p><strong>Style:</strong> {{project.style}}</p>
        <p><strong>Audience:</strong> {{project.targetAudience}}</p>
        <p><strong>Due Date:</strong> {{project.dueDate | date}}</p>
        <p><strong>File Format:</strong> {{project.fileFormat}}</p>
      </div>
    </div>
  </div>
  <div class="col-md-6">
    <div class="card mt-2 mb-2">
      <div class="card-header">Package</div>
      <div class="card-body">
        <p><strong>Category:</strong> {{project?.packageModel?.packageCategory?.name}}</p>
        <p *ngIf="project?.packageModel?.packageSubcategory"><strong>Subcategory:</strong> {{project?.packageModel?.packageSubcategory?.name}}</p>
        <p *ngIf="project?.packageModel?.audience"><strong>Audience:</strong> {{project?.packageModel?.audience?.name}}</p>
        <p><strong>Description:</strong> {{project?.packageModel?.description}}</p>
        <p *ngIf="project?.packageModel?.licenseType == 1"><strong>License:</strong> One-Year</p>
        <p *ngIf="project?.packageModel?.licenseType == 2"><strong>License:</strong> Perpetual</p>
        <p><strong>Cost:</strong> {{project?.projectCost | currency:'USD'}}</p>
      </div>
    </div>
  </div>
  <div class="col-12" *ngIf="project?.status === 0">
    <div class="card mt-2 mb-2">
      <div class="card-header">Project Pending</div>
      <div class="card-body">
        <p>Your project request has been received! We'll review your request soon.</p>
      </div>
    </div>
  </div>
  <div class="col-12" *ngIf="project?.status === 1">
    <div class="card mt-2 mb-2">
      <div class="card-header">Project Accepted</div>
      <div class="card-body">
        <p>This project has been accepted by ApexxVO!</p>
        <p>We'll get to work recording your voiceover, and will notify you by email when the recording is ready to review.</p>
      </div>
    </div>
  </div>
  <div class="col-12" *ngIf="project?.status === 2">
    <div class="card mt-2 mb-2">
      <div class="card-header">Project Declined</div>
      <div class="card-body">
        <p *ngIf="!project?.declineReason">This project has been declined by ApexxVO.</p>
        <p *ngIf="project?.declineReason">This project has been declined by ApexxVO for the following reason:</p>
        <p *ngIf="project?.declineReason">{{project?.declineReason}}</p>
        <p>Your deposit authorization has been canceled, and your credit card will not be charged.</p>
      </div>
    </div>
  </div>
  <div class="col-12" *ngIf="project?.status === 3">
    <div class="card mt-2 mb-2">
      <div class="card-header">Recording Preview</div>
      <div class="card-body">
        <p>Please click "preview" next to each recording to hear the audio. You will be able to download once the project has been paid in full.</p>
        <table class="table table-bordered mt-4">
          <tr *ngFor="let r of recordings">
            <td width="50%" class="text-center">{{r.name}}</td>
            <td width="50%" class="text-center">
              <vm-player theme="light" style="--vm-player-theme: #6653a3">
                <vm-audio cross-origin>
                  <source data-src="{{getFileUrl(r.id!, r.url!)}}" type="audio/mp3" />
                </vm-audio>
                <vm-ui>
                  <vm-controls full-width>
                    <vm-playback-control></vm-playback-control>
                    <vm-current-time></vm-current-time>
                    <vm-scrubber-control></vm-scrubber-control>
                    <vm-volume-control></vm-volume-control>
                    <vm-settings-control></vm-settings-control>
                  </vm-controls>
                </vm-ui>
              </vm-player>
            </td>
          </tr>
        </table>
        <div class="row text-center" *ngIf="!showPayment">
          <div class="col-6">
            <button class="btn btn-success" (click)="startPayment()">Accept and Pay</button>
          </div>
          <div class="col-6">
            <button class="btn btn-danger" (click)="declineProject()">Decline Project</button>
          </div>
        </div>
      </div>
    </div>
    <div class="card mt-2 mb-2" *ngIf="showPayment">
      <div class="card-header">Final Payment</div>
      <div class="card-body">
        <div class="row" >
          <div class="col-12 mt-2">
            <p>Your credit card will be immediately charged for the remaining balance due toward the project.</p>
            <div class="row">
              <div class="col-md-6 text-center mt-2">
                <h5>Remaining Payment: {{purchaseAmount | currency:'USD'}}</h5>
                <p class="small">Payments accepted securely using Stripe.</p>
              </div>
              <div class="col-md-6 mt-3 mb-2">
                <app-stripe-purchase [projectId]="id" (paymentSuccess)="onStripeSuccess($event)" (amount)="onIntentResolve($event)"></app-stripe-purchase>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="col-12" *ngIf="project?.status === 4">
    <div class="card mt-2 mb-2">
      <div class="card-header">Recording Download</div>
      <div class="card-body">
        <p>To download the source files, click "download" next to each file.</p>
        <table class="table table-bordered mt-4">
          <tr *ngFor="let r of recordings">
            <td width="50%" class="text-center">{{r.name}}</td>
            <td width="50%" class="text-center">
              <vm-player theme="light" style="--vm-player-theme: #6653a3">
                <vm-audio cross-origin>
                  <source data-src="{{getFileUrl(r.id!, r.url!)}}" type="audio/mp3" />
                </vm-audio>
                <vm-ui>
                  <vm-controls full-width>
                    <vm-playback-control></vm-playback-control>
                    <vm-current-time></vm-current-time>
                    <vm-scrubber-control></vm-scrubber-control>
                    <vm-volume-control></vm-volume-control>
                    <vm-settings-control></vm-settings-control>
                  </vm-controls>
                </vm-ui>
              </vm-player>
              <button class="btn btn-sm btn-success" (click)="downloadFile(r.url!)">Download</button>
            </td>
          </tr>
        </table>
      </div>
    </div>
<!--    <div class="card mt-2 mb-2">-->
<!--      <div class="card-header">Request Updates</div>-->
<!--      <div class="card-body">-->
<!--        <p>To request updates to the recording files, please enter the description of the updates below, and attach a new script if necessary.</p>-->
<!--        <textarea [(ngModel)]="updateRequest" rows="5" class="form-control"></textarea>-->
<!--        <label for="projectScript" class="form-label mt-3">Please upload the script for your voiceover project.</label>-->
<!--        <input id="projectScript" type="file" class="form-control" accept=".doc,.docx,.pdf,.txt" />-->
<!--        <span class="small">Word (.doc, .docx), PDF, and Text (.txt) files are accepted.<br/></span>-->
<!--        <button class="btn btn-primary mt-3">Request Updates</button>-->
<!--      </div>-->
<!--    </div>-->
    <div class="card mt-2 mb-2">
      <div class="card-header">License Information</div>
      <div class="card-body" *ngIf="project && project.packageModel && project.termsAgreedDate">
        <p *ngIf="project.packageModel.licenseType === 1">This project has a one-year license that expires on {{getExpirationDate(project.termsAgreedDate!) | date}}. You can upgrade your license to a perpetual license below. </p>
        <p *ngIf="project.packageModel.licenseType === 2">This project is licensed in perpetuity.</p>

        <p *ngIf="project.packageModel.licenseType === 1" >To upgrade to a perpetual license, you will owe {{ getProjectCost(project) | currency}}</p>
<!--        <button *ngIf="project.packageModel.licenseType === 1" class="btn btn-primary" (click)="showUpgrade = true">Upgrade My license</button>-->
<!--        <div *ngIf="showUpgrade">-->
<!--          <pre class="text-center">Stripe goes here.</pre>-->
<!--          <br/>-->
<!--          <button class="btn btn-block btn-primary">Submit license upgrade payment</button>-->
<!--        </div>-->
      </div>
    </div>
  </div>
  <div class="col-12" *ngIf="project?.status === 5">
    <div class="card mt-2 mb-2">
      <div class="card-header">Project Declined</div>
      <div class="card-body">
        <p>This project has been declined, and recording files are no longer available.</p>
      </div>
    </div>
  </div>
  <div class="col-12" *ngIf="project?.status === 7">
    <div class="card mt-2 mb-2">
      <div class="card-header">Project Quoted</div>
      <div class="card-body">
        <p>This project has been quoted at {{project?.projectCost | currency:'USD'}}.</p>
        <p>If you accept, your deposit authorization will be charged, and the remaining cost will be due upon completion.</p>
        <div class="row text-center" *ngIf="!showPayment">
          <div class="col-6">
            <button class="btn btn-success" (click)="acceptCustomProject()">Accept Quote</button>
          </div>
          <div class="col-6">
            <button class="btn btn-danger" (click)="declineProject()">Decline Project</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
