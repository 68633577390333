import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {PaymentsService} from '../../api';
import {environment} from '../../environments/environment';
import {loadStripe, Stripe, StripeCardElement, StripeCardElementOptions, StripeElements} from '@stripe/stripe-js';

@Component({
  selector: 'app-stripe-hold',
  templateUrl: './stripe-hold.component.html',
  styleUrls: ['./stripe-hold.component.scss']
})
export class StripeHoldComponent implements OnInit {

  @Input() projectId!: string;
  @Output() paymentSuccess = new EventEmitter<boolean>();

  clientSecret!: string;
  stripe!: Stripe | null;
  elements!: StripeElements | undefined;
  cardElement!: StripeCardElement | undefined;
  errorMessage: string;
  status: number;

  constructor(private paymentsService: PaymentsService) {
    this.errorMessage = '';
    this.status = 0;
  }

  ngOnInit(): void {
    const apiKey = environment.stripe.apiKey;

    const cardOptions: StripeCardElementOptions = {
      style: {
        base: {
          fontSize: '16px',
          color: '#32325d'
        }
      }
    };

    loadStripe(apiKey).then((x) => {
      this.stripe = x;
      this.elements = this.stripe?.elements();
      this.cardElement = this.elements?.create('card', cardOptions);
      this.cardElement?.mount('#card-element');
      this.cardElement?.on('change', ({error}) => {
        if (error) {
          this.errorMessage = error.message;
        } else {
          this.errorMessage = '';
        }
      });
    });

    this.getIntent();

    const form = document.getElementById('payment-form');

    form?.addEventListener('submit', (ev) => {
      ev.preventDefault();
      this.confirmPayment();
    });
  }

  getIntent(): void {
    console.log('getIntent called');
    this.paymentsService.apiPaymentsIntentDepositProjectIdGet(this.projectId)
      .subscribe(x => {
        this.clientSecret = x;
        console.log('getIntent complete');
      });
  }

  confirmPayment(): void {
    console.log('confirmPayment called');
    this.status = 1;

    if (!this.stripe || !this.cardElement || !this.clientSecret) {
      this.status = -1;
      throw new Error('Stripe not initialized properly');
    }

    this.stripe?.confirmCardPayment(this.clientSecret, {
      payment_method: {
        card: this.cardElement,
        billing_details: {
        }
      }
    }).then((result) => {
      if (result.error) {
        // Show error to your customer (e.g., insufficient funds)
        this.status = -1;
        this.errorMessage = result.error.message ?? '';
        console.log(result.error.message);
      } else {
        // The payment has been processed!
        console.log('payment intent call complete');
        console.log(result.paymentIntent.status);
        this.paymentsService.apiPaymentsIntentVerifyLatestPaymentProjectIdGet(this.projectId, true).subscribe(x => {
          if (x) {
            this.status = 2;
            this.paymentSuccess.emit(true);
          } else {
            this.status = -1;
          }
        });
      }
    });
  }
}

