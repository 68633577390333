<div class="row">
  <div class="col-12">
    <h3 class="text-center mt-3 mb-3">Voiceover Package Details</h3>
  </div>
  <div class="col-12">
    <div class="card mt-2 mb-2">
      <div class="card-header">Package Info</div>
      <div class="card-body" *ngIf="package">
        <p><strong>Category:</strong> {{package.packageCategory?.name}}</p>
        <p *ngIf="package.packageSubcategory"><strong>Subcategory:</strong> {{package.packageSubcategory.name}}</p>
        <p *ngIf="package.audience"><strong>Audience:</strong> {{package.audience.name}}</p>
        <p><strong>Description:</strong> {{package.description}}</p>
        <p *ngIf="package.licenseType == 1"><strong>License:</strong> One-Year</p>
        <p *ngIf="package.licenseType == 2"><strong>License:</strong> Perpetual</p>
        <p><strong>Cost:</strong> {{package.isCustomQuote ? 'Custom' : package.cost | currency:'USD'}}</p>
      </div>
    </div>
  </div>
  <div class="col-md-8"></div>
  <div class="col-md-4 text-right">
    <button class="btn btn-block btn-success" (click)="goToProfile()" *ngIf="isAuthenticated">Start New Project</button>
    <button class="btn btn-block btn-success" (click)="goToLoginAndNext()" *ngIf="!isAuthenticated">Get Started</button>
    <p class="small" *ngIf="!isAuthenticated">You will be asked to register or log in to continue.</p>
  </div>
</div>
