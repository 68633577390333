import { Component, OnInit } from '@angular/core';
import {FilesService, Project, ProjectsService} from '../../api';
import {ActivatedRoute, Router} from '@angular/router';

@Component({
  selector: 'app-project-upload-script',
  templateUrl: './project-upload-script.component.html',
  styleUrls: ['./project-upload-script.component.scss']
})
export class ProjectUploadScriptComponent implements OnInit {

  id!: string;
  project?: Project;
  file: any;
  sub: any;

  constructor(private route: ActivatedRoute,
              private router: Router,
              private projectService: ProjectsService,
              private fileService: FilesService) {
  }

  ngOnInit(): void {
    this.sub = this.route.params.subscribe(params => {
      this.id = params.id;
      this.projectService.apiProjectsIdGet(this.id).subscribe(x => this.project = x);
    });
  }

  onFileChange(event: any): void {
    if (event.target.files.length > 0) {
      this.file = event.target.files[0];
      console.log('updated file');
      console.log(event.target.files[0]);
      console.log(this.file);
    } else {
      this.file = null;
      console.log('no file');
    }
  }

  submitScript(): void {
    if (this.file == null) {
      console.log('No file');
      return;
    }
    console.log('File to send...');
    console.log(this.file);
    this.fileService.apiFilesScriptProjectIdPost(this.id, this.file).subscribe(x => {
      this.router.navigateByUrl(`/project/${this.id}/terms`);
    });
  }

}
