<div class="row">
  <div class="col-12">
    <h3 class="text-center mt-3 mb-3">Upload Your Script</h3>
  </div>
  <div class="col-12">
    <div class="card mt-2 mb-2">
      <div class="card-header">Project Details</div>
      <div class="card-body" *ngIf="project">
        <p><strong>Title:</strong> {{project.title}}</p>
        <p><strong>Description:</strong> {{project.description}}</p>
        <p><strong>Style:</strong> {{project.style}}</p>
        <p><strong>Audience:</strong> {{project.targetAudience}}</p>
        <p><strong>Due Date:</strong> {{project.dueDate | date}}</p>
        <p><strong>File Format</strong> {{project.fileFormat}}</p>
        <p><strong>Package</strong> {{project.packageModel?.description}}</p>
        <p *ngIf="project.packageModel?.audience"><strong>Audience:</strong> {{project.packageModel?.audience?.name}}</p>
        <p *ngIf="project.packageModel?.licenseType == 1"><strong>License:</strong> One-Year</p>
        <p *ngIf="project.packageModel?.licenseType == 2"><strong>License:</strong> Perpetual</p>
        <p><strong>Cost:</strong> {{project.packageModel?.isCustomQuote ? 'Custom' : project.packageModel?.cost | currency:'USD'}}</p>
      </div>
    </div>
  </div>
  <div class="col-12">
    <div class="card mt-2 mb-2">
      <div class="card-header">File Upload</div>
      <div class="card-body">
        <div class="mb-3">
          <label for="projectScript" class="form-label">Please upload the script for your voiceover project.</label>
          <input id="projectScript" type="file" class="form-control" accept=".doc,.docx,.pdf,.txt" (change)="onFileChange($event)" #fileInput/>
          <span class="small">Word (.doc, .docx), PDF, and Text (.txt) files are accepted.</span>
        </div>
      </div>
    </div>
  </div>
  <div class="col-md-9"></div>
  <div class="col-md-3 text-right">
    <button class="btn btn-primary btn-block" (click)="submitScript()" [disabled]="(!file)">Save and Next Page</button>
  </div>
</div>
