import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {Package, PackagesService, PublicService} from '../../api';
import {AuthService} from '@auth0/auth0-angular';
import {RerouteService} from '../reroute.service';

@Component({
  selector: 'app-package-detail',
  templateUrl: './package-detail.component.html',
  styleUrls: ['./package-detail.component.scss']
})
export class PackageDetailComponent implements OnInit {

  id?: number;
  package?: Package;
  sub: any;
  isAuthenticated: boolean;

  constructor(private route: ActivatedRoute,
              private router: Router,
              private packageService: PackagesService,
              private publicService: PublicService,
              public authService: AuthService,
              private rerouteService: RerouteService
              ) {
    this.isAuthenticated = false;
  }

  ngOnInit(): void {
    this.sub = this.route.params.subscribe(params => {
      const parsed = parseInt(params.packageId, 10);
      if (isNaN(parsed)) { this.router.navigateByUrl('/'); }
      this.id = parsed;
      this.publicService.apiPublicPackagesIdGet(this.id).subscribe(x => this.package = x);
    });

    this.authService.isAuthenticated$.subscribe(x => this.isAuthenticated = x);

    window.scrollTo(0, 0);
  }

  goToProfile(): void {
    this.router.navigateByUrl(`/profile?packageId=${this.id}`);
  }

  goToLoginAndNext(): void {
    this.rerouteService.setRoute(`/profile?packageId=${this.id}`);
    this.authService.loginWithRedirect({screen_hint: 'signup', redirect_uri: this.rerouteService.getRedirectUri()});
  }
}
