<div class="row">
  <div class="col-12">
    <h3 class="text-center mt-3 mb-3">Project Submission Confirmation</h3>
  </div>
  <div class="col-12">
    <div class="card mt-2 mb-2">
<!--      <div class="card-header">Project Terms</div>-->
      <div class="card-body text-center">
        <h4 class="mb-4">Congratulations!</h4>
        <p>Your project has been submitted!</p>
        <p>ApexxVO will review your project request. You'll receive an email with additional details once your project has been reviewed.</p>
        <p>Thank you for working with ApexxVO!</p>
      </div>
    </div>
  </div>
  <div class="col-12">
    <div class="card mt-2 mb-2">
      <div class="card-header">Project Details</div>
      <div class="card-body" *ngIf="project">
        <p><strong>Title:</strong> {{project.title}}</p>
        <p><strong>Description:</strong> {{project.description}}</p>
        <p><strong>Style:</strong> {{project.style}}</p>
        <p><strong>Audience:</strong> {{project.targetAudience}}</p>
        <p><strong>Due Date:</strong> {{project.dueDate | date}}</p>
        <p><strong>File Format</strong> {{project.fileFormat}}</p>
        <p><strong>Package</strong> {{project.packageModel?.description}}</p>
        <p *ngIf="project.packageModel?.audience"><strong>Audience:</strong> {{project.packageModel?.audience?.name}}</p>
        <p *ngIf="project.packageModel?.licenseType == 1"><strong>License:</strong> One-Year</p>
        <p *ngIf="project.packageModel?.licenseType == 2"><strong>License:</strong> Perpetual</p>
        <p><strong>Cost:</strong> {{project.packageModel?.isCustomQuote ? 'Custom Quote' : project.packageModel?.cost | currency:'USD'}}</p>
        <p><strong>Deposit Pre-Approved:</strong> {{50 | currency:'USD'}}</p>
      </div>
    </div>
  </div>
  <div class="col-md-8"></div>
  <div class="col-md-4 text-right">
    <button class="btn btn-success btn-block" [routerLink]="['/dashboard']">Go To Project Dashboard</button>
  </div>
</div>
