/**
 * ApexxVO.API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type ProjectStatus = 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7;

export const ProjectStatus = {
    NUMBER_0: 0 as ProjectStatus,
    NUMBER_1: 1 as ProjectStatus,
    NUMBER_2: 2 as ProjectStatus,
    NUMBER_3: 3 as ProjectStatus,
    NUMBER_4: 4 as ProjectStatus,
    NUMBER_5: 5 as ProjectStatus,
    NUMBER_6: 6 as ProjectStatus,
    NUMBER_7: 7 as ProjectStatus
};

