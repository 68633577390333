import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {PricingGridComponent} from './pricing-grid/pricing-grid.component';
import {PackageDetailComponent} from './package-detail/package-detail.component';
import {ProjectTermsAndDepositComponent} from './project-terms-and-deposit/project-terms-and-deposit.component';
import {ProjectConfirmationComponent} from './project-confirmation/project-confirmation.component';
import {UserDashboardComponent} from './user-dashboard/user-dashboard.component';
import {DetailsComponent} from './user-dashboard/details/details.component';
import {UserProfileComponent} from './user-profile/user-profile.component';
import {InitialProjectPurchaseComponent} from './initial-project-purchase/initial-project-purchase.component';
import {ProjectUploadScriptComponent} from './project-upload-script/project-upload-script.component';
import {AdminDetailsComponent} from './admin-details/admin-details.component';
import {AdminDashboardComponent} from './admin-dashboard/admin-dashboard.component';
import {StripeHoldComponent} from './stripe-hold/stripe-hold.component';
import {AuthGuard} from '@auth0/auth0-angular';
import {RerouterComponent} from './rerouter/rerouter.component';

const routes: Routes = [
  {
    path: '',
    component: PricingGridComponent
  },
  {
    path: 'package/:packageId',
    component: PackageDetailComponent
  },
  {
    path: 'profile',
    component: UserProfileComponent
  },
  {
    path: 'project/step-1/:packageId',
    component: InitialProjectPurchaseComponent,
    // canActivate: [AuthGuard],
  },
  {
    path: 'project/:id/upload-script',
    component: ProjectUploadScriptComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'project/:id/terms',
    component: ProjectTermsAndDepositComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'project/:id/confirmation',
    component: ProjectConfirmationComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'dashboard',
    component: UserDashboardComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'dashboard/details/:id',
    component: DetailsComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'admin/dashboard',
    component: AdminDashboardComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'admin/dashboard/details/:id',
    component: AdminDetailsComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'stripe/hold',
    component: StripeHoldComponent
  },
  {
    path: 'reroute',
    component: RerouterComponent
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
