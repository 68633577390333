export * from './files.service';
import { FilesService } from './files.service';
export * from './packages.service';
import { PackagesService } from './packages.service';
export * from './payments.service';
import { PaymentsService } from './payments.service';
export * from './projects.service';
import { ProjectsService } from './projects.service';
export * from './public.service';
import { PublicService } from './public.service';
export * from './userProfiles.service';
import { UserProfilesService } from './userProfiles.service';
export const APIS = [FilesService, PackagesService, PaymentsService, ProjectsService, PublicService, UserProfilesService];
