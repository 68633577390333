<div class="jumbotron mt-4">
  <div class="text-center mb-4"><img src="assets/apexxvologo.png" height="200px" width="200px"/></div>
  <h1>Choose Your Voiceover Package</h1>
  <p>ApexxVO offers a variety of packages for your voiceover needs. When you purchase a package, only a $50 deposit is required.<br/>
  The remaining cost is only paid once you've reviewed your voiceover recording and complete your purchase.</p>
</div>
<ul ngbNav #nav="ngbNav" [(activeId)]="active" class="nav-tabs nav-fill nav-justified">
  <li [ngbNavItem]="1">
    <a ngbNavLink>Radio</a>
    <ng-template ngbNavContent>
      <h3 class="text-center">Radio Spots</h3>
      <p class="small text-center">Used for broadcast on AM/FM radio</p>
      <div class="mt-4 mb-4">
        <ngb-accordion [closeOthers]="true" activeIds="radio-local">
          <ngb-panel id="radio-local">
            <ng-template ngbPanelTitle>
              <span>Local</span>&nbsp;&nbsp;<span class="small">(Less than 1 million)</span>
            </ng-template>
            <ng-template ngbPanelContent>
              <table class="table table-bordered text-center mb-0">
                <thead>
                <tr>
                  <th scope="col" width="33.33%">Duration</th>
                  <th scope="col" width="33.33%">One-Year</th>
                  <th scope="col" width="33.33%">Perpetuity</th>
                </tr>
                </thead>
                <tbody>
                <tr>
                  <th scope="row">:30</th>
                  <td>
                    <button type="button" class="btn btn-sm btn-success" [routerLink]="['/package', 1]">$200</button>
                  </td>
                  <td>
                    <button type="button" class="btn btn-sm btn-secondary" [routerLink]="['/package', 2]">$500</button>
                  </td>
                </tr>
                <tr>
                  <th scope="row">:60</th>
                  <td>
                    <button type="button" class="btn btn-sm btn-success" [routerLink]="['/package', 3]">$300</button>
                  </td>
                  <td>
                    <button type="button" class="btn btn-sm btn-secondary" [routerLink]="['/package', 4]">$700</button>
                  </td>
                </tr>
                <tr>
                  <th scope="row">:120</th>
                  <td>
                    <button type="button" class="btn btn-sm btn-success" [routerLink]="['/package', 5]">$500</button>
                  </td>
                  <td>
                    <button type="button" class="btn btn-sm btn-secondary" [routerLink]="['/package', 6]">$900</button>
                  </td>
                </tr>
                </tbody>
              </table>
            </ng-template>
          </ngb-panel>
          <ngb-panel id="radio-regional">
            <ng-template ngbPanelTitle>
              <span>Regional</span>&nbsp;&nbsp;<span class="small">(More than 1 million)</span>
            </ng-template>
            <ng-template ngbPanelContent>
              <table class="table table-bordered text-center mb-0">
                <thead>
                <tr>
                  <th scope="col" width="33.33%">Duration</th>
                  <th scope="col" width="33.33%">One-Year</th>
                  <th scope="col" width="33.33%">Perpetuity</th>
                </tr>
                </thead>
                <tbody>
                <tr>
                  <th scope="row">:30</th>
                  <td>
                    <button type="button" class="btn btn-sm btn-success" [routerLink]="['/package', 7]">$150</button>
                  </td>
                  <td>
                    <button type="button" class="btn btn-sm btn-secondary" [routerLink]="['/package', 8]">$550</button>
                  </td>
                </tr>
                <tr>
                  <th scope="row">:60</th>
                  <td>
                    <button type="button" class="btn btn-sm btn-success" [routerLink]="['/package', 9]">$250</button>
                  </td>
                  <td>
                    <button type="button" class="btn btn-sm btn-secondary" [routerLink]="['/package', 10]">$750</button>
                  </td>
                </tr>
                <tr>
                  <th scope="row">:120</th>
                  <td>
                    <button type="button" class="btn btn-sm btn-success" [routerLink]="['/package', 11]">$300</button>
                  </td>
                  <td>
                    <button type="button" class="btn btn-sm btn-secondary"[routerLink]="['/package', 12]">$1,150</button>
                  </td>
                </tr>
                </tbody>
              </table>
            </ng-template>
          </ngb-panel>
          <ngb-panel id="radio-national">
            <ng-template ngbPanelTitle>
              <span>National</span>&nbsp;&nbsp;<span class="small">(More than half the nation)</span>
            </ng-template>
            <ng-template ngbPanelContent>
              <table class="table table-bordered text-center mb-0">
                <thead>
                <tr>
                  <th scope="col" width="33.33%">Duration</th>
                  <th scope="col" width="33.33%">One-Year</th>
                  <th scope="col" width="33.33%">Perpetuity</th>
                </tr>
                </thead>
                <tbody>
                <tr>
                  <th scope="row">:30</th>
                  <td>
                    <button type="button" class="btn btn-sm btn-success" [routerLink]="['/package', 13]">$400</button>
                  </td>
                  <td>
                    <button type="button" class="btn btn-sm btn-secondary" [routerLink]="['/package', 14]">$1,200</button>
                  </td>
                </tr>
                <tr>
                  <th scope="row">:60</th>
                  <td>
                    <button type="button" class="btn btn-sm btn-success" [routerLink]="['/package', 15]">$700</button>
                  </td>
                  <td>
                    <button type="button" class="btn btn-sm btn-secondary" [routerLink]="['/package', 16]">$1,600</button>
                  </td>
                </tr>
                <tr>
                  <th scope="row">:120</th>
                  <td>
                    <button type="button" class="btn btn-sm btn-success" [routerLink]="['/package', 17]">$1,200</button>
                  </td>
                  <td>
                    <button type="button" class="btn btn-sm btn-secondary" [routerLink]="['/package', 18]">$2,000</button>
                  </td>
                </tr>
                </tbody>
              </table>
            </ng-template>
          </ngb-panel>
        </ngb-accordion>
      </div>
      <h4 class="text-center">Bumps, Tags, Opener & Closer</h4>
      <div class="mt-4 mb-4">
        <table class="table table-bordered text-center mb-0">
          <thead>
          <tr>
            <th scope="col" width="66.66%">Description</th>
            <th scope="col" width="33.33%">Cost</th>
          </tr>
          </thead>
          <tbody>
          <tr>
            <td>Up to 20 Seconds</td>
            <td>
              <button type="button" class="btn btn-sm btn-secondary" [routerLink]="['/package', 19]">$50</button>
            </td>
          </tr>
          <tr>
            <td>National Syndicated Program</td>
            <td>
              <button type="button" class="btn btn-sm btn-secondary" [routerLink]="['/package', 20]">$1,800</button>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
      <h4 class="text-center">Station Imaging / Promos</h4>
      <div class="mt-4 mb-4">
        <table class="table table-bordered text-center mb-0">
          <thead>
          <tr>
            <th scope="col" width="66.66%">Description</th>
            <th scope="col" width="33.33%">Cost</th>
          </tr>
          </thead>
          <tbody>
          <tr>
            <td>Custom Quote</td>
            <td>
              <button type="button" class="btn btn-sm btn-secondary" [routerLink]="['/package', 21]">Request Quote</button>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
    </ng-template>
  </li>
  <li [ngbNavItem]="2">
    <a ngbNavLink>Television</a>
    <ng-template ngbNavContent>
      <h3 class="text-center">Television Spots</h3>
      <p class="small text-center">Used for broadcast/cable television</p>
      <div class="mt-4 mb-4">
        <ngb-accordion [closeOthers]="true" activeIds="tv-local">
          <ngb-panel id="tv-local">
            <ng-template ngbPanelTitle>
              <span>Local</span>&nbsp;&nbsp;<span class="small">(Less than 1 million)</span>
            </ng-template>
            <ng-template ngbPanelContent>
              <table class="table table-bordered text-center mb-0">
                <thead>
                <tr>
                  <th scope="col" width="33.33%">Duration</th>
                  <th scope="col" width="33.33%">One-Year</th>
                  <th scope="col" width="33.33%">Perpetuity</th>
                </tr>
                </thead>
                <tbody>
                <tr>
                  <th scope="row">:30</th>
                  <td>
                    <button type="button" class="btn btn-sm btn-success" [routerLink]="['/package', 22]">$500</button>
                  </td>
                  <td>
                    <button type="button" class="btn btn-sm btn-secondary" [routerLink]="['/package', 23]">$2,000</button>
                  </td>
                </tr>
                <tr>
                  <th scope="row">:60</th>
                  <td>
                    <button type="button" class="btn btn-sm btn-success" [routerLink]="['/package', 24]">$700</button>
                  </td>
                  <td>
                    <button type="button" class="btn btn-sm btn-secondary" [routerLink]="['/package', 25]">$2,800</button>
                  </td>
                </tr>
                <tr>
                  <th scope="row">:120</th>
                  <td>
                    <button type="button" class="btn btn-sm btn-success" [routerLink]="['/package', 26]">$900</button>
                  </td>
                  <td>
                    <button type="button" class="btn btn-sm btn-secondary" [routerLink]="['/package', 27]">$3,500</button>
                  </td>
                </tr>
                </tbody>
              </table>
            </ng-template>
          </ngb-panel>
          <ngb-panel id="tv-regional">
            <ng-template ngbPanelTitle>
              <span>Regional</span>&nbsp;&nbsp;<span class="small">(More than 1 million)</span>
            </ng-template>
            <ng-template ngbPanelContent>
              <table class="table table-bordered text-center mb-0">
                <thead>
                <tr>
                  <th scope="col" width="33.33%">Duration</th>
                  <th scope="col" width="33.33%">One-Year</th>
                  <th scope="col" width="33.33%">Perpetuity</th>
                </tr>
                </thead>
                <tbody>
                <tr>
                  <th scope="row">:30</th>
                  <td>
                    <button type="button" class="btn btn-sm btn-success" [routerLink]="['/package', 28]">$400</button>
                  </td>
                  <td>
                    <button type="button" class="btn btn-sm btn-secondary" [routerLink]="['/package', 29]">$2,500</button>
                  </td>
                </tr>
                <tr>
                  <th scope="row">:60</th>
                  <td>
                    <button type="button" class="btn btn-sm btn-success" [routerLink]="['/package', 30]">$575</button>
                  </td>
                  <td>
                    <button type="button" class="btn btn-sm btn-secondary" [routerLink]="['/package', 31]">$4,000</button>
                  </td>
                </tr>
                <tr>
                  <th scope="row">:120</th>
                  <td>
                    <button type="button" class="btn btn-sm btn-success" [routerLink]="['/package', 32]">$800</button>
                  </td>
                  <td>
                    <button type="button" class="btn btn-sm btn-secondary" [routerLink]="['/package', 33]">$6,500</button>
                  </td>
                </tr>
                </tbody>
              </table>
            </ng-template>
          </ngb-panel>
          <ngb-panel id="tv-national">
            <ng-template ngbPanelTitle>
              <span>National</span>&nbsp;&nbsp;<span class="small">(More than half the nation)</span>
            </ng-template>
            <ng-template ngbPanelContent>
              <table class="table table-bordered text-center mb-0">
                <thead>
                <tr>
                  <th scope="col" width="33.33%">Duration</th>
                  <th scope="col" width="33.33%">One-Year</th>
                  <th scope="col" width="33.33%">Perpetuity</th>
                </tr>
                </thead>
                <tbody>
                <tr>
                  <th scope="row">:30</th>
                  <td>
                    <button type="button" class="btn btn-sm btn-success" [routerLink]="['/package', 34]">$1,400</button>
                  </td>
                  <td>
                    <button type="button" class="btn btn-sm btn-secondary" [routerLink]="['/package', 35]">$5,000</button>
                  </td>
                </tr>
                <tr>
                  <th scope="row">:60</th>
                  <td>
                    <button type="button" class="btn btn-sm btn-success" [routerLink]="['/package', 36]">$1,800</button>
                  </td>
                  <td>
                    <button type="button" class="btn btn-sm btn-secondary" [routerLink]="['/package', 37]">$7,500</button>
                  </td>
                </tr>
                <tr>
                  <th scope="row">:120</th>
                  <td>
                    <button type="button" class="btn btn-sm btn-success" [routerLink]="['/package', 38]">$2,600</button>
                  </td>
                  <td>
                    <button type="button" class="btn btn-sm btn-secondary" [routerLink]="['/package', 39]">$9,000</button>
                  </td>
                </tr>
                </tbody>
              </table>
            </ng-template>
          </ngb-panel>
        </ngb-accordion>
      </div>
      <h4 class="text-center">Bumps, Tags, Opener & Closer</h4>
      <div class="mt-4 mb-4">
        <table class="table table-bordered text-center mb-0">
          <thead>
          <tr>
            <th scope="col" width="66.66%">Description</th>
            <th scope="col" width="33.33%">Cost</th>
          </tr>
          </thead>
          <tbody>
          <tr>
            <td>Up to 20 Seconds</td>
            <td>
              <button type="button" class="btn btn-sm btn-secondary" [routerLink]="['/package', 40]">$50</button>
            </td>
          </tr>
          <tr>
            <td>National Syndicated Program</td>
            <td>
              <button type="button" class="btn btn-sm btn-secondary" [routerLink]="['/package', 41]">$5,200</button>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
      <h4 class="text-center">Station Imaging / Promos</h4>
      <div class="mt-4 mb-4">
        <table class="table table-bordered text-center mb-0">
          <thead>
          <tr>
            <th scope="col" width="66.66%">Description</th>
            <th scope="col" width="33.33%">Cost</th>
          </tr>
          </thead>
          <tbody>
          <tr>
            <td>Custom Quote</td>
            <td>
              <button type="button" class="btn btn-sm btn-secondary" [routerLink]="['/package', 42]">Request Quote</button>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
    </ng-template>
  </li>
  <li [ngbNavItem]="3">
    <a ngbNavLink>Trailers</a>
    <ng-template ngbNavContent>
      <h3 class="text-center">Trailers</h3>
      <p class="small text-center">Used for feature film or other entertainment production</p>
      <div class="mt-4 mb-4">
        <table class="table table-bordered text-center mb-0">
          <thead>
          <tr>
            <th scope="col" width="66.66%">Duration</th>
            <th scope="col" width="33.33%">Cost</th>
          </tr>
          </thead>
          <tbody>
          <tr>
            <td>:30</td>
            <td>
              <button type="button" class="btn btn-sm btn-secondary" [routerLink]="['/package', 43]">$900</button>
            </td>
          </tr>
          <tr>
            <td>:60</td>
            <td>
              <button type="button" class="btn btn-sm btn-secondary" [routerLink]="['/package', 44]">$1,500</button>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
    </ng-template>
  </li>
  <li [ngbNavItem]="4">
    <a ngbNavLink>Gaming</a>
    <ng-template ngbNavContent>
      <h3 class="text-center">Gaming</h3>
      <p class="small text-center">Utilized for computer, handheld, console or mobile app games</p>
      <div class="mt-4 mb-4">
        <p class="text-center">Gaming projects are available by custom quote only.</p>
        <p class="text-center"><button type="button" class="btn btn-secondary" [routerLink]="['/package', 45]">Request a Quote</button></p>
      </div>
    </ng-template>
  </li>
  <li [ngbNavItem]="5">
    <a ngbNavLink>Corporate</a>
    <ng-template ngbNavContent>
      <h3 class="text-center">Non-Broadcast / Corporate / Training / E-Learning</h3>
      <p class="small text-center">Used in-house for company training or explanation</p>
      <div class="mt-4 mb-4">
        <table class="table table-bordered text-center mb-0">
          <thead>
          <tr>
            <th scope="col" width="66.66%">Duration</th>
            <th scope="col" width="33.33%">Cost</th>
          </tr>
          </thead>
          <tbody>
          <tr>
            <td>1-3 Minutes</td>
            <td>
              <button type="button" class="btn btn-sm btn-secondary" [routerLink]="['/package', 46]">$350</button>
            </td>
          </tr>
          <tr>
            <td>3-8 Minutes</td>
            <td>
              <button type="button" class="btn btn-sm btn-secondary" [routerLink]="['/package', 47]">$500</button>
            </td>
          </tr>
          <tr>
            <td>8-15 Minutes</td>
            <td>
              <button type="button" class="btn btn-sm btn-secondary" [routerLink]="['/package', 48]">$625</button>
            </td>
          </tr>
          <tr>
            <td>30-60 Minutes</td>
            <td>
              <button type="button" class="btn btn-sm btn-secondary" [routerLink]="['/package', 49]">$850</button>
            </td>
          </tr>
          <tr>
            <td>Each Additional Hour</td>
            <td>
              $300
            </td>
          </tr>
          <tr>
            <td>Custom Quote</td>
            <td>
              <button type="button" class="btn btn-sm btn-secondary" [routerLink]="['/package', 50]">Request Quote</button>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
    </ng-template>
  </li>
  <li [ngbNavItem]="6">
    <a ngbNavLink>Narrative</a>
    <ng-template ngbNavContent>
      <h3 class="text-center">Long Form Narrative / Documentary</h3>
      <p class="small text-center">Narration utilized for extended pieces intended for air/cable</p>
      <div class="mt-4 mb-4">
        <table class="table table-bordered text-center mb-0">
          <thead>
          <tr>
            <th scope="col" width="66.66%">Duration</th>
            <th scope="col" width="33.33%">Cost</th>
          </tr>
          </thead>
          <tbody>
          <tr>
            <td>Up to 30 Minutes</td>
            <td>
              <button type="button" class="btn btn-sm btn-secondary" [routerLink]="['/package', 51]">$1,200</button>
            </td>
          </tr>
          <tr>
            <td>Up to 60 Minutes</td>
            <td>
              <button type="button" class="btn btn-sm btn-secondary" [routerLink]="['/package', 52]">$2,100</button>
            </td>
          </tr>
          <tr>
            <td>Each Additional Hour</td>
            <td>
              $1,500
            </td>
          </tr>
          <tr>
            <td>Custom Quote</td>
            <td>
              <button type="button" class="btn btn-sm btn-secondary" [routerLink]="['/package', 53]">Request Quote</button>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
    </ng-template>
  </li>
  <li [ngbNavItem]="7">
    <a ngbNavLink>Phone</a>
    <ng-template ngbNavContent>
      <h3 class="text-center">Phone System Menu / Voicemail / On-Hold</h3>
      <p class="small text-center">Used for telephone menus, hold messages or loudspeaker announcements</p>
      <div class="mt-4 mb-4">
        <table class="table table-bordered text-center mb-0">
          <thead>
          <tr>
            <th scope="col" width="66.66%">Duration</th>
            <th scope="col" width="33.33%">Cost</th>
          </tr>
          </thead>
          <tbody>
          <tr>
            <td>Up to 500 Words</td>
            <td>
              <button type="button" class="btn btn-sm btn-secondary" [routerLink]="['/package', 54]">$450</button>
            </td>
          </tr>
          <tr>
            <td>501-1000 Words</td>
            <td>
              <button type="button" class="btn btn-sm btn-secondary" [routerLink]="['/package', 55]">$575</button>
            </td>
          </tr>
          <tr>
            <td>1001-2500 Words</td>
            <td>
              <button type="button" class="btn btn-sm btn-secondary" [routerLink]="['/package', 56]">$700</button>
            </td>
          </tr>
          <tr>
            <td>2501-4000 Words</td>
            <td>
              <button type="button" class="btn btn-sm btn-secondary" [routerLink]="['/package', 57]">$1,300</button>
            </td>
          </tr>
          <tr>
            <td>More Than 4000 Words</td>
            <td>
              <button type="button" class="btn btn-sm btn-secondary" [routerLink]="['/package', 58]">$.35/WORD</button>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
    </ng-template>
  </li>
</ul>
<div [ngbNavOutlet]="nav" class="border-left border-right border-bottom pt-4 pl-4 pr-4"></div>
