import { Component, OnInit } from '@angular/core';
import {ErrorService} from '../error.service';

@Component({
  selector: 'app-master-alert',
  templateUrl: './master-alert.component.html',
  styleUrls: ['./master-alert.component.scss']
})
export class MasterAlertComponent implements OnInit {


  constructor(public errorService: ErrorService) {
  }

  ngOnInit(): void {
  }

}
