import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { PricingGridComponent } from './pricing-grid/pricing-grid.component';
import { PackageDetailComponent } from './package-detail/package-detail.component';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {ApiModule, Configuration, ConfigurationParameters} from '../api';
import {environment} from '../environments/environment';
import { ProjectTermsAndDepositComponent } from './project-terms-and-deposit/project-terms-and-deposit.component';
import { ProjectConfirmationComponent } from './project-confirmation/project-confirmation.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import { UserDashboardComponent } from './user-dashboard/user-dashboard.component';
import { DetailsComponent } from './user-dashboard/details/details.component';
import { AdminDashboardComponent } from './admin-dashboard/admin-dashboard.component';
import { UserProfileComponent } from './user-profile/user-profile.component';
import { InitialProjectPurchaseComponent } from './initial-project-purchase/initial-project-purchase.component';
import { ProjectUploadScriptComponent } from './project-upload-script/project-upload-script.component';
import { AdminDetailsComponent } from './admin-details/admin-details.component';
import {NgxFileDropModule} from 'ngx-file-drop';
import {AuthHttpInterceptor, AuthModule} from '@auth0/auth0-angular';
import { StripeHoldComponent } from './stripe-hold/stripe-hold.component';

// Import here to include globally
import '@stripe/stripe-js';
import { RerouterComponent } from './rerouter/rerouter.component';
import { LoadingComponent } from './loading/loading.component';
import {VimeModule} from '@vime/angular';
import { StripePurchaseComponent } from './stripe-purchase/stripe-purchase.component';
import { MasterAlertComponent } from './master-alert/master-alert.component';
import {ErrorInterceptor} from './error.interceptor';

@NgModule({
  declarations: [
    AppComponent,
    PricingGridComponent,
    PackageDetailComponent,
    ProjectTermsAndDepositComponent,
    ProjectConfirmationComponent,
    UserDashboardComponent,
    DetailsComponent,
    AdminDashboardComponent,
    UserProfileComponent,
    InitialProjectPurchaseComponent,
    ProjectUploadScriptComponent,
    AdminDetailsComponent,
    StripeHoldComponent,
    RerouterComponent,
    LoadingComponent,
    StripePurchaseComponent,
    MasterAlertComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    AuthModule.forRoot({
      audience: environment.auth0.audience,
      domain: environment.auth0.domain,
      clientId: environment.auth0.clientId,
      httpInterceptor: {
        allowedList: [
          {
            uri: `${environment.apiConfiguration.basePath}/api/*`,
            allowAnonymous: true
          }
        ],
      }
    }),
    NgbModule,
    HttpClientModule,
    ApiModule,
    FormsModule,
    ReactiveFormsModule,
    NgxFileDropModule,
    VimeModule
  ],
  providers: [
    {
      provide: Configuration,
      useFactory: () => new Configuration(
        {
          basePath: environment.apiConfiguration.basePath
        }
      ),
      deps: [],
      multi: false
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthHttpInterceptor,
      multi: true
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
